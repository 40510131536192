<template>
  <!--SALES BY EMPLOYEES-->
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("sales.byEmployees") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic">
      <BarChartEmployees />
    </v-card-text>
    <v-data-table
      v-else
      :headers="headersByEmployees"
      :items="list"
      :items-per-page="5"
      id="salesByEmployees-table"
    >
      <template v-slot:item.tattooer_id="{ item }">
        {{ item.tattooer_id }}
      </template>
      <template v-slot:item.fullname="{ item }">
        {{ item.user.fullname }}
      </template>
      <template v-slot:item.sales_quantity="{ item }">
        {{ item.sales_quantity }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "SalesByEmployee",
  components: {
    BarChartEmployees: () =>
      import("@/components/stats/sales/graphics/SalesByEmployeeGraphic"),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.salesByEmployee;
    },
  },
  data() {
    return {
      graphic: true,
      headersByEmployees: [
        {
          text: this.$t("id"),
          align: "center",
          sortable: false,
          value: "tattooer_id",
        },
        {
          text: this.$t("fullname"),
          align: "center",
          sortable: false,
          value: "tattooer.user.fullname",
        },
        {
          text: this.$t("stats.sales"),
          align: "center",
          sortable: false,
          value: "sales_quantity",
        },
      ],
    };
  },
};
</script>

<style></style>
